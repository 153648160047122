<template>  
  <div class="review">
    <div class="review__rating">
      <img :src="require('@/resources/images/assets/star.png')" v-for="(star, index) in 5" :key="index">
    </div>
    <h4 class="review__header">{{ review.header }}</h4>
    <p class="review__text">{{ review.reviewText}}</p>
    <p class="review__author">{{ review.author }}</p>
    <p class="review__note">{{ review.note }}</p>
  </div>
</template>

<script>
export default {
  props: {
    review: Object
  }
}
</script>

<style lang="scss">
@import './styles.scss';
</style>